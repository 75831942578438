import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { Form, Formik } from "formik";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import "../auth.scss";
import { useHistory, useLocation } from "react-router-dom";
import { getParentPath } from "app/utils/routerUtils";
import isEmpty from "lodash/isEmpty";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { object, string } from "yup";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";

const ResetPassword = ({ handleSubmit, initialValues = {} }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const handleCancelButtonClick = useCallback(() => {
		const parentPath = getParentPath(pathname);
		history.push({ pathname: `${parentPath}/login` });
	}, [pathname]);

	return (
		<div className="auth auth__resetpassword">
			<HeadMetadata page="resetpassword" />
			<div className="auth__main">
				<div className="auth__headline">
					<FormattedMessage id="auth.reset.password.title" />
				</div>

				<div className="auth__intro">
					<FormattedMessage id="auth.reset.password.intro" />
				</div>

				<Formik
					enableReinitialize
					initialValues={{
						email: "",
						...initialValues,
					}}
					validationSchema={object({
						email: string()
							.required({
								id: "error.email.required",
							})
							.email(() => ({
								id: "error.email.format",
							})),
					})}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={handleSubmit}
				>
					{({ errors = {}, isSubmitting, setFieldError, status = {}, setStatus }) => (
						<Form
							className="auth__form"
							onChange={event => {
								// Permet de supprimer l'état en erreur du champ en erreur lorsque le user commence à le  modifier
								// Actuellement pas possible de le faire facilement avec formik
								// https://github.com/formium/formik/issues/2727
								if (!isEmpty(errors)) {
									setFieldError(event.target.name);
								}

								// reset status to avoid success message to be dispayed with errors messages after a first successful submit
								setStatus({});
							}}
						>
							<FormErrorMessages errors={errors} isSubmitting={isSubmitting} />

							<InputFormik
								name="email"
								id="email"
								isRequired={true}
								label={<FormattedMessage id="auth.email.label" />}
								data-testid="email-input"
							/>

							<div className="auth__action auth__resetpassword__action">
								{errors._error && (
									<AlertMessage
										alertType={ALERT_TYPE.ERROR}
										message={<FormattedMessage id={errors._error.id} />}
									/>
								)}

								<Button
									className="auth__button"
									submit
									variant="primary"
									loading={isSubmitting}
									data-testid="reset-password-button"
								>
									<FormattedMessage id="auth.reset.pasword.button.label" />
								</Button>
								{status.success && !isSubmitting && (
									<AlertMessage
										message={
											<FormattedMessage id="auth.reset.password.success.label" />
										}
										alertType={ALERT_TYPE.SUCCESS}
									/>
								)}
								{!status.success && (
									<Button
										variant="secondary"
										onClick={handleCancelButtonClick}
										data-testid="cancel-button"
									>
										<FormattedMessage id="cancel.action" />
									</Button>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

ResetPassword.propTypes = {
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.object,
};

export default memo(ResetPassword);
