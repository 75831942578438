import { useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import Button from "app/pages/.shared/form/Button";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { getParentPath } from "app/utils/routerUtils";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { validationSchemaChangePassword } from "app/pages/Auth/ChangePassword/ChangePasswordSchema";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import "../auth.scss";

const ChangePassword = ({ handleSubmit = () => {} }) => {
	const location = useLocation();
	const history = useHistory();

	const handleCancel = useCallback(() => {
		const parentPath = getParentPath(location.pathname);
		history.push({ pathname: `${parentPath}/login` });
	}, [location.pathname]);

	return (
		<div className="auth">
			<HeadMetadata page="changepassword" />
			<div className="auth__main">
				<Formik
					initialValues={{
						password: "",
						confirmPassword: "",
					}}
					validationSchema={validationSchemaChangePassword}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{({ status = {}, errors = {}, setFieldError, setStatus, isSubmitting }) => (
						<Form
							className="auth__form"
							onChange={event => {
								if (!isEmpty(errors)) {
									setFieldError(event.target.name);
								}
								setStatus({});
							}}
						>
							<div className="auth__headline">
								<FormattedMessage id="auth.change.password.title" />
							</div>

							<div className="auth__intro">
								<FormattedMessage id="auth.changer.password.intro" />
							</div>

							<FormErrorMessages errors={errors} isSubmitting={isSubmitting} />

							<InputFormik
								type="password"
								label={<FormattedMessage id="auth.change.new.password.label" />}
								name="password"
								id="auth-password"
								data-testid="password-input"
							/>

							<InputFormik
								type="password"
								label={<FormattedMessage id="auth.confirm.password.label" />}
								name="confirmPassword"
								id="auth-confirm-password"
								data-testid="confirm-password-input"
							/>

							<div className="auth__action auth__resetpassword__action">
								{status?.success === false && errors._error && errors._error.id && (
									<AlertMessage
										message={<FormattedMessage id={errors._error.id} />}
										alertType={ALERT_TYPE.ERROR}
									/>
								)}
								<Button
									className="auth__button"
									submit
									variant="primary"
									loading={isSubmitting}
									data-testid="change-password-button"
								>
									<FormattedMessage id="auth.change.password.button.label" />
								</Button>
								<Button
									variant="primary"
									className="button--inverted"
									onClick={handleCancel}
									data-testid="cancel-button"
								>
									<FormattedMessage id="cancel.action" />
								</Button>
								{status?.success && (
									<div className="profile__password__success-message">
										<AlertMessage
											message={
												<FormattedMessage id="general.success.button.label" />
											}
											alertType={ALERT_TYPE.SUCCESS}
										/>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

ChangePassword.propTypes = {
	handleSubmit: PropTypes.func,
};

export default ChangePassword;
