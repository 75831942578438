import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { parseQueries } from "app/utils/routerUtils";
import { mapErrorHttpCodeToMessage } from "app/utils/utils";
import { HTTP_STATUS_CODES } from "app/constants";
import { authSuccess, changePassword, logout } from "app/pages/Auth/AuthActions";
import ChangePassword from "app/pages/Auth/ChangePassword/ChangePassword.jsx";

const ChangePasswordContainer = ({
	changePassword = () => {},
	authSuccess = () => {},
	logout = () => {},
}) => {
	const history = useHistory();
	const location = useLocation();

	const handleSubmit = (values, { setSubmitting, setErrors, setStatus }) => {
		const query = parseQueries(location.search);
		const authorization = query.token || false;

		if (!authorization) {
			setSubmitting(false);
			setStatus({ success: false });
			setErrors({ _error: { id: "error.must.be.authenticated" } });
		} else {
			changePassword(
				{
					password: values.password,
				},
				query.token
			).then(res => {
				setSubmitting(false);
				if (res?.status === HTTP_STATUS_CODES.OK) {
					setStatus({ success: true });
					authSuccess(res, authorization);
					history.push({
						pathname: "/listing",
					});
				} else {
					if (res?.status === HTTP_STATUS_CODES.FORBIDDEN) {
						logout();
						history.replace({ pathname: "/home/login" });
					} else if (res?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
						setErrors({ _error: { id: "error.session.expired" } });
					} else {
						setErrors({ _error: mapErrorHttpCodeToMessage(res?.status) });
					}
					setStatus({ success: false });
				}
			});
		}
	};

	return <ChangePassword handleSubmit={handleSubmit} />;
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			changePassword,
			authSuccess,
			logout,
		},
		dispatch
	),
});

ChangePasswordContainer.propTypes = {
	changePassword: PropTypes.func,
	authSuccess: PropTypes.func,
	logout: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps
)(ChangePasswordContainer);
