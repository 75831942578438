import PropTypes from "prop-types";
import ResetPassword from "app/pages/Auth/ResetPassword/ResetPassword.jsx";
import { useCallback, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetPassword } from "app/pages/Auth/AuthActions";
import AppGlobalsContext from "app/AppGlobalsContext";
import { sendTagOnError } from "app/utils/analytics";
import { HTTP_STATUS_CODES } from "app/constants";

const ResetPasswordContainer = ({ resetPassword, email }) => {
	const { shop } = useContext(AppGlobalsContext);

	const handleSubmit = useCallback((values, { setErrors, setStatus }) => {
		const payload = {
			email: values.email,
			url: `${window.location.origin}/${shop}/home/changepassword`,
		};

		return resetPassword(payload)
			.then(res => {
				if (res.status === 200) {
					setStatus({ success: true });
				} else {
					setStatus({ success: false });
					onSubmitFail(res, setErrors);
				}
			})
			.catch(error => {
				setStatus({ success: false });
				onSubmitFail(error, setErrors);
			});
	}, []);

	const onSubmitFail = useCallback((res, setErrors) => {
		let errorMessage;

		switch (res.status) {
			case HTTP_STATUS_CODES.NOT_FOUND:
				errorMessage = {
					id: "error.email.not.registered",
				};
				break;
			default:
				errorMessage = {
					id: "error.generic",
				};
				break;
		}

		// _error pour le composant AlertMessage. A refacto potentiellement
		setErrors({ _error: errorMessage });
		sendTagOnError(
			`${res.config.method} ${res.config.url} : ${res.status} - ${res.statusText}`
		);
	}, []);

	return (
		<ResetPassword
			handleSubmit={handleSubmit}
			initialValues={{
				email,
			}}
		/>
	);
};

ResetPasswordContainer.propTypes = {
	resetPassword: PropTypes.func,
	email: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		email: state.email || "",
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ resetPassword }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPasswordContainer);
