import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import Paragraphs from "app/pages/.shared/Paragraphs";
import PropTypes from "prop-types";
import { memo, useContext, useCallback, createRef } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import "./SmartDPHome.scss";
import LoginContainer from "app/pages/Auth/Signin/LoginContainer";
import { FormattedMessage } from "react-intl";
import SignupContainer from "app/pages/Auth/Signup/SignupContainer";
import ChangePasswordContainer from "app/pages/Auth/ChangePassword/ChangePasswordContainer";
import ResetPasswordContainer from "app/pages/Auth/ResetPassword/ResetPasswordContainer";
import { SmartDPReasons, ProductsCarouselBlock } from "app/pages/.shared/DynamicBlocks/blocks";
import flatten from "lodash/flatten";
import { scrollToElement } from "app/utils/scroller";

const IMG_SIZES = {
	medium: { width: 750, height: 320 },
	small: { width: 750, height: 320 },
	large: { width: 2880 },
	xlarge: { width: 2880 },
};

const SmartDPHome = ({ home = {}, homeBlocks = [] }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const authRef = createRef();
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const images = home.images;
	const texts = home.texts;
	const match = useRouteMatch();
	const location = useLocation();

	const reasonsToBookBlock = homeBlocks.find(b => b._type === "reasonsToBook").items;
	const ppCarouselBlock = flatten(
		homeBlocks.map(block => (block._type === "multiBlocks" ? block.children : block))
	).find(b => b._type === "PPCarousel");

	const AuthForm = (
		<Switch>
			<Route
				path={`${match.url}/login`}
				render={() => {
					return (
						<LoginContainer
							signinButtonLabel={
								<FormattedMessage id="home.header.button.signin.now" />
							}
							headline={false}
							showTopFacebook={true}
						/>
					);
				}}
			/>
			<Route
				path={`${match.url}/signup`}
				render={() => {
					return <SignupContainer headline={false} showTopFacebook={true} />;
				}}
			/>
			<Route
				path={`${match.url}/changepassword`}
				render={() => {
					return <ChangePasswordContainer location={location} />;
				}}
			/>
			<Route path={`${match.url}/resetpassword`} component={ResetPasswordContainer} />
			<Redirect to={`${match.url}/signup`} />
		</Switch>
	);

	const handleClick = useCallback(() => {
		scrollToElement({ element: authRef.current, disableSmoothScroll: false });
	}, [authRef]);

	return (
		<div className="sdp-home">
			<div className="sdp-home__hero">
				<BackgroundProgressiveImg
					sizes={IMG_SIZES}
					resolution={resolution}
					backgroundPosition={"right"}
					thumbnail={
						isMobile ? images?.imageMobileThumbnail : images?.imageDesktopThumbnail
					}
					src={isMobile ? images?.imageMobile : images?.imageDesktop}
				/>
				<div className="sdp-home__hero-content">
					<div className="sdp-home__marketing">
						<div className="sdp-home__marketing-container">
							<Switch>
								<Route path={`${match.url}/login`}>
									<h2>
										<Paragraphs
											paragraphs={texts?.signinTitle}
											enableHTMLFormatting={true}
										/>
									</h2>
									<div className="sdp-home__subtitle">
										<Paragraphs
											paragraphs={texts?.signinDescription}
											enableHTMLFormatting={true}
										/>
									</div>
								</Route>

								<Route path={`${match.url}/signup`}>
									<h2>
										<Paragraphs
											paragraphs={texts?.signupTitle}
											enableHTMLFormatting={true}
										/>
									</h2>
									<div className="sdp-home__subtitle">
										<Paragraphs
											paragraphs={texts?.signupDescription}
											enableHTMLFormatting={true}
										/>
									</div>
								</Route>

								<Route path={`${match.url}/changepassword`}>
									<h2>
										<FormattedMessage id="auth.reset.password.marketing.text" />
									</h2>
								</Route>

								<Route path={`${match.url}/resetpassword`}>
									<h2>
										<FormattedMessage id="auth.reset.password.marketing.text" />
									</h2>
								</Route>
							</Switch>
						</div>
					</div>
					{!isMobile && (
						<div className="sdp-home__form" ref={authRef}>
							{AuthForm}
						</div>
					)}
				</div>
			</div>
			{isMobile && (
				<div className="sdp-home__form" ref={authRef}>
					{AuthForm}
				</div>
			)}
			<div className="sdp-home__blocks">
				{ppCarouselBlock && (
					<div className="sdp-home__blocks-item">
						<ProductsCarouselBlock
							introduction={ppCarouselBlock.intro}
							title={ppCarouselBlock.title}
							cta={ppCarouselBlock.cta}
							onItemClick={handleClick}
						/>
					</div>
				)}
				{reasonsToBookBlock && (
					<div className="sdp-home__blocks-item">
						<SmartDPReasons reasonsToBook={reasonsToBookBlock} />
					</div>
				)}
			</div>
		</div>
	);
};

SmartDPHome.propTypes = {
	home: PropTypes.object,
	homeBlocks: PropTypes.array,
};

export default memo(SmartDPHome);
