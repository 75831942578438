import { ref, object, string } from "yup";

export const validationSchemaChangePassword = object().shape({
	password: string()
		.min(6, { id: "error.password.min.length" })
		.required({ id: "error.new.password.required" }),
	confirmPassword: string()
		.oneOf([ref("password"), null], { id: "error.password.do.not.match" })
		.required({ id: "error.new.confirmed.password.required" }),
});

export const validationSchema = object().shape({
	actualPassword: string().required({ id: "error.current.password.required" }),
	changePassword: string()
		.min(6, { id: "error.password.min.length" })
		.required({ id: "error.new.password.required" }),
	changeConfirmPassword: string()
		.oneOf([ref("changePassword"), null], { id: "error.password.do.not.match" })
		.required({ id: "error.new.confirmed.password.required" }),
});
